<template>
	<div id="competition">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">竞赛管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item"><el-button size="small" @click="openDialog(1)">添加活动</el-button></div>
				</div>
				<el-table :data="StaffList" height="72.9vh" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="title" label="竞赛标题" align="center"></el-table-column>
					<el-table-column prop="start_time" label="开始时间" align="center"></el-table-column>
					<el-table-column prop="end_time" label="结束时间" align="center"></el-table-column>
					<el-table-column prop="mark" label="竞赛内容" align="center"></el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加课室 -->
			<el-dialog title="添加活动" :visible.sync="addSubject" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							竞赛标题
						</div>
						<el-input v-model="title" placeholder="请输入竞赛标题" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							竞赛内容
						</div>
						<el-input v-model="mark" type="textarea" placeholder="请输入竞赛内容" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							开始日期
						</div>
						<el-date-picker  value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="start_time" type="date" placeholder="选择日期" style="width: 75%;"></el-date-picker>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							结束日期
						</div>
						<el-date-picker  value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="end_time" type="date" placeholder="选择日期" style="width: 75%;"></el-date-picker>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			gradeIndex: 0,
			gradeList: [],
			gradeDataIndex: 0,
			classList: [],
			classIndex: -1,
			StudentList: [],
			studenShow: false,
			studenid: '',
			StaffList: [],
			addSubject: false,
			loading: false,
			page: 1,
			limit: 11,
			total: 0,
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			teachers: [],
			nums: [],
			selectShow: false,
			selectList: [],
			isBnt: false,
			title: '',
			student_ids: '',
			mark: '',
			start_time:'',
			end_time: ''
		};
	},
	mounted() {
		this.getInfo();
		this.teacherList();
		this.gradeData();
		this.classData();
	},
	methods: {
		// 切换年级班级
		gradeTap(index) {
			this.gradeIndex = index;
			if (index == 0) {
				this.gradeDataIndex = 0;
				this.classIndex = -1;
			} else {
				this.gradeDataIndex = -1;
				this.classIndex = 0;
			}
			this.getStudentList();
		},
		// 年级列表
		gradeData() {
			this.$api.setting.getGradeList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					this.gradeList = data;
					this.getStudentList();
				}
			});
		},
		// 班级列表
		classData() {
			this.$api.setting.getClassList({}).then(res => {
				this.classList = res.data.data.rows;
			});
		},
		getClassList() {
			this.$api.setting
				.getClassList({
					filter: JSON.stringify({ grade_id: this.grade_id })
				})
				.then(res => {
					this.classList1 = res.data.rows;
				});
		},
		// 学生列表
		getStudentList() {
			let data = {};
			if (this.gradeDataIndex != -1) {
				data.grade_id = this.gradeList[this.gradeDataIndex].id;
			}
			if (this.classIndex != -1) {
				console.log(this.classList);
				data.grade_id = this.classList[this.classIndex].grade_id;
				data.class_id = this.classList[this.classIndex].id;
			}
			if (this.sousuo) {
				data.keyword = this.sousuo;
			}
			this.$api.student.csdList(data).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					for (let i in data) {
						data[i].checked = false;
					}
					this.StudentList = data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		gradeDataTap(index) {
			this.gradeDataIndex = index;
			this.getStudentList();
		},
		classTap(index) {
			this.classIndex = index;
			this.getStudentList();
		},
		StudenTap(item) {
			let arr = [];

			for (let i in this.StudentList) {
				if (this.StudentList[i].id == item.id && !this.StudentList[i].checked) {
					this.StudentList[i].checked = true;
				} else if (this.StudentList[i].id == item.id && this.StudentList[i].checked) {
					this.StudentList[i].checked = false;
				}
				if (this.StudentList[i].checked) {
					arr.push(this.StudentList[i].id);
				}
			}
			this.student_ids = arr;
		},

		handleClose1(done) {
			this.typeIndex = 0;
			this.dataIndex = 0;
			done();
		},
		// 重置
		reset() {
			this.teachers = [];
			this.teacherList();
		},
		allTap() {
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						this.teacher_list[i].data[j].teacher[t].checked = true;
						arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
			this.isBnt = true;
		},
		allTap1() {
			this.reset();
			this.isBnt = false;
		},
		QuanTap() {
			let arr = [];
			for (let t in this.teacher_list[this.typeIndex].data[this.dataIndex].teacher) {
				this.teacher_list[this.typeIndex].data[this.dataIndex].teacher[t].checked = true;
				arr.push(this.teacher_list[this.typeIndex].data[this.dataIndex].teacher[t].teacher_id);
			}
			this.teachers = this.teachers.concat(arr);
			this.teachers = this.unique(this.teachers);
			this.teacher_list[this.typeIndex].data[this.dataIndex].isQuan = true;
		},
		QuanTap1() {
			let arr = [];
			for (let t in this.teacher_list[this.typeIndex].data[this.dataIndex].teacher) {
				this.teacher_list[this.typeIndex].data[this.dataIndex].teacher[t].checked = false;
			}
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].checked) {
							arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
			this.teacher_list[this.typeIndex].data[this.dataIndex].isQuan = false;
		},
		// 选择老师
		teachTap(item) {
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
						if (this.teacher_list[i].data[j].teacher[t].checked) {
							arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
		},
		removeTag() {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teachers.length) {
							for (let c in this.teachers) {
								if (this.teacher_list[i].data[j].teacher[t].teacher_id == this.teachers[c]) {
									this.teacher_list[i].data[j].teacher[t].checked = true;
								} else {
									this.teacher_list[i].data[j].teacher[t].checked = false;
								}
							}
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].teacher_id);
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		closeShow() {
			this.init();
			this.addSubject = false;
		},
		openDialog(type, item) {
			this.addSubject = true;
		},
		init() {
			this.title = '';
			this.mark = '';
			this.start_time = '';
			this.end_time = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo();
		},
		// 教研室列表
		getInfo() {
			this.$api.setting
				.getCompetitionList({
					page: this.page,
					limit: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.total = res.data.data.total;
						this.StaffList = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init();
			done();
		},
		// 课程安排
		submit() {
			this.addStaff();
		},
		addStaff() {
			this.$api.setting
				.addCompetition({
					title:this.title,	
					mark:this.mark,
					start_time:this.start_time,
					end_time:this.end_time,
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#competition {
	.content {
		margin-top: 15px;
		background-color: #ffffff;
		height: 86vh;
		padding: 0 25px;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table {
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
